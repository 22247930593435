// CustomModal.jsx
import React from "react";
import { Modal, Button } from "react-bootstrap"; // Import Bootstrap components

const CustomModal = ({ showModal, handleClose, content, title, size }) => {
  return (
    <Modal show={showModal} onHide={handleClose} size={size} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
