import React from "react";
import Home from "../components/Home/Home";
import Services from "../components/Services/Services";
import ContactUs from "../components/ContactUs/ContactUs";

const MainContainer = () => {
  return (
    <>
      <Home />
      <Services />
      <ContactUs />
    </>
  );
};

export default MainContainer;
