// Loader.js
import React from "react";
import { MoonLoader } from "react-spinners";
import "../../styles/Loader.css";

const CustomLoader = () => {
  return (
    <div className="loader-container">
      <MoonLoader color="#00BFFF" size={50} />
      <p className="loading-text">
        Loading<span className="dot-1">.</span>
        <span className="dot-2">.</span>
        <span className="dot-3">.</span>
      </p>
    </div>
  );
};

export default CustomLoader;
