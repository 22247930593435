// Terms.jsx
import React from "react";

const Terms = () => {
  return (
    <div>
      <h2>Terms & Conditions</h2>
      {/* Add your terms content here */}
    </div>
  );
};

export default Terms;
