// Privacy.jsx
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Privacy.css";

const Privacy = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  return (
    <div className="privacy">
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="title">Privacy Policy</h1>
            <p>
              Effective Date: January 11th 2020
              <br />
              This page describes how Nova Network processes the personal data
              collected from data subjects (clients, prospects…). If you have
              any questions or concerns regarding this page, please contact
              <a href="mailto:privacy@novanetwork.in">
                {" "}
                privacy@novanetwork.in
              </a>
              .
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>Nova Network Website</h2>
            <p>
              Nova Network, collectively known as Nova Network (“us”, “we” or
              “our”) operates the www.novanetwork.in website. Nova Network is a
              custom business to business list research, data as a service
              company. This page informs you of our policies regarding the
              collection, use, and disclosure of personal data when you use our
              website and services and the choices you have associated with the
              data. We understand that you care about how your personal
              information is used. This privacy policy describes how Nova
              Network manages personal information collected, received, or
              maintained. We collect information pertaining to businesses and
              business people (“Business Information”) and all other types of
              information through our website and other online services (the
              “Site”).
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>How does Nova Network collect Website Visitor Information?</h2>
            <p>
              When you browse our website, you are not required to provide any
              personal information. However, we may collect
              non-personally-identifiable information, utilizing technologies
              such as cookies and other similar tools commonly used by
              commercial websites. These technologies enable web servers to
              recognize the computer used to access a website, storing
              information accessed through your browser to streamline activities
              and enhance your online experience. Information gathered may
              include the date and time of visits, pages viewed, IP addresses,
              links to/from any page, and time spent on the site.
            </p>
            <p>
              Information submitted through website forms, such as requests for
              product demonstrations or article downloads, may include personal
              details like your name, job title, company, email address, or
              phone number. Your submission is associated with the IP address
              you are using, which is automatically assigned to your computer by
              your Internet Service Provider.
            </p>
            <p>
              Any information collected is used for internal business purposes,
              including investigations, sales, and marketing efforts. We may use
              IP addresses to calculate usage levels, diagnose server issues,
              and administer services. Additionally, we may derive approximate
              location data from IP addresses.
            </p>
            <p>
              If you wish to inquire about the information we have collected
              about you, or if you would like to correct or delete any
              information, please contact us at{" "}
              <a href="mailto:privacy@novanetwork.in">privacy@novanetwork.in</a>
              . If you prefer not to be contacted via phone, you can opt-out by
              emailing us at{" "}
              <a href="mailto:privacy@novanetwork.in">privacy@novanetwork.in</a>
              .
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>How does Nova Network collect Customer Information?</h2>
            <p>
              Visitors to our Site may choose to submit their name, email
              address, and/or other information so that they can learn more
              about our services, register to take part in a Nova Network
              sponsored event, or participate in a survey, contest, or
              sweepstakes, among other things.
              <br />
              In order to use Nova Network's services and solutions, you may be
              required to register as a user. From time to time, we may use your
              email address to send you information and keep you informed of
              products and services in which you might be interested. You will
              always be provided with an opportunity to opt out of receiving
              such emails. Your contact information may also be used to reach
              you regarding issues concerning your use of our Site, including
              changes to this privacy policy.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>How Nova Network uses its Customer Information?</h2>
            <p>
              Customer Information may be utilized for Nova Network's legitimate
              business interests in connection with the use of our Services.
              This includes addressing user inquiries, fulfilling user requests,
              providing customer service, sending administrative information,
              and personalizing the user experience with our Services. We may
              also utilize Customer Information to gain a better understanding
              of our users in general, enhance the content and functionality of
              our Services, and communicate with you in the future regarding
              services, promotions, opportunities, and other pertinent
              information about Nova Network that we believe will be of interest
              to you. Furthermore, we may employ Customer Information to
              investigate and address potential breaches of our security or
              license agreements.
            </p>
            <p>
              Nova Network will not share Customer Information with any third
              party except as necessary for legitimate purposes, such as in
              connection with a bona fide legal dispute where such information
              is relevant, in response to valid legal process, or as required by
              law. We strive to obtain confidentiality agreements from any
              individual or entity to whom Customer Information is disclosed,
              ensuring that recipients are committed to employing appropriate
              technological security measures.
            </p>
            <p>
              Nova Network implements reasonable security and backup procedures
              to safeguard Customer Information. However, in the unlikely event
              of data loss or corruption, Nova Network bears no responsibility
              or liability. We encourage users to retain copies of all Uploaded
              Information on their own systems.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>
              How does Nova Network get the Contacts and Business information
              for its custom B2B list research services?
            </h2>
            <p>
              <strong>Business Contact Information</strong> refers to various
              details related to an individual's business or professional
              identity, including their Name, Title, Company Name, Business
              Email Address, Business Phone Number, Work Address, Public
              Professional Profile Address, and any additional information
              pertaining to their business role and responsibilities.
            </p>

            <p>
              Nova Network employs human teams for desk-based research, web
              research, secondary research, and telephone research to gather and
              compile profiles of business individuals and companies according
              to the specific criteria outlined by our clients. These compiled
              profiles, which we term as "Contacts," are sourced from a diverse
              range of public platforms such as Company Websites, News Releases,
              Blogs, Social Networks, Trade Shows, Conferences, and various
              other online and offline sources.
            </p>

            <p>
              All contact and business information obtained through our custom
              web and desk-based research, along with telephone research, is
              meticulously validated and verified via telephone to ensure its
              accuracy and relevance. The resulting database of Contacts is then
              tailored to meet the specific needs of our clients and is made
              available to them, as well as to our strategic partners.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>
              The General Data Protection Regulation GDPR for EU Residents
            </h2>
            <p>
              Nova Network complies with the recent GDPR regulation by
              processing data along the GDPR norms. Under GDPR, there are six
              grounds to process personal data, all equally valid. Two of these
              are relevant to our business, which are consent or legitimate
              interest. As Recital 47 of the GDPR states, "The processing of
              personal data for direct marketing purposes may be regarded as
              carried out for a legitimate interest." Using legitimate interest
              as the basis for B2B marketing involves ensuring key conditions
              are met:
            </p>

            <ul>
              <li>
                The processing must relate to the legitimate interests of your
                business or a specified third party, providing that the
                interests or fundamental rights of the data subject do not
                override the business' legitimate interest.
              </li>
              <li>
                The processing must be necessary to achieve the legitimate
                interests of the organization.
              </li>
            </ul>

            <p>
              Along with the above compliance, Nova Network does not share
              personal data with customers/partners, records all data processing
              activities, does not source any sensitive information such as
              religion, political inclinations, gender, age, etc., and has an
              EXPLICIT OPT-IN, OPT-OUT process.
            </p>

            <p>
              As a 'data processor', Nova Network follows the following data
              privacy requirements honoring the GDPR law (Article 13 and 14).
              Nova Network provides notice to all data subjects as required by
              GDPR Article 13 or 14, as appropriate, and honors the rights of
              data subjects provided in Articles 12-23, including the right to
              be forgotten.
            </p>

            <p>
              Nova Network is a custom B2B List Research Company, and we custom
              build B2B contact databases as per our client's custom
              criteria/requirement brief. With our clients' permission, we, on
              behalf of our client, speak with the contact and inform the
              individuals about the collection and use of their personal data,
              seek opt-in for our clients' email marketing communication,
              retention periods for the data, and who it is shared with. This is
              a key transparency requirement under the GDPR, providing privacy
              information to individuals at the time of collecting their
              personal data. When Nova Network obtains personal data from other
              sources, we provide individuals with privacy information within a
              reasonable period of obtaining the data and no later than a month.
              If any EU resident information is sourced in Nova Network's
              database, then Nova Network will provide such individual with a
              notice detailing the information Nova Network has on such a
              person, the purpose for which it will be used, and informing such
              person of their rights with respect to such information, including
              the right to know what information Nova Network/its client
              possesses on them, to correct such information, or to opt-out of
              data collection entirely. Such persons may opt out of the Nova
              Network database by emailing{" "}
              <a href="mailto:privacy@novanetwork.in">privacy@novanetwork.in</a>
              .
            </p>

            <p>
              Information we provide to people is concise, transparent,
              intelligible, easily accessible, and in clear and plain language.
              We conduct regular reviews, and where necessary, update our
              privacy information. If there are any new uses of an individual's
              personal data, we make sure to bring it to their attention before
              we start processing.
            </p>

            <p>
              For information received under the Privacy Shield, Nova Network
              will require third parties to whom Nova Network discloses personal
              information to safeguard that personal information consistent with
              this Policy by contract, obligating those third parties to provide
              at least the same level of protection as is required by the
              Privacy Shield Principles. EU and Swiss individuals may choose to
              opt-out of such disclosures.
            </p>

            <p>
              In compliance with the EU-US Privacy Shield Principles, Nova
              Network commits to resolve complaints about your privacy and our
              collection or use of your personal information. European Union
              individuals with enquiries or complaints regarding this privacy
              policy should first contact Nova Network at
              <a href="mailto:privacy@novanetwork.in">
                {" "}
                privacy@novanetwork.in
              </a>
              .
            </p>
          </Col>
        </Row>
        {/* Add more sections here */}
      </Container>
    </div>
  );
};

export default Privacy;
