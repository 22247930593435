// Footer.jsx
import React from "react";
import { Link } from "react-router-dom"; // Import Link component for routing
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa"; // Import social media icons
import "./Footer.css";
import logo from "../../assets/nova-network-logo.png";
import gdpr from "../../assets/gdpr-logo.png";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-top">
          {/* First Column: Company Logo */}
          <div className="footer-column">
            <img src={logo} alt="Company Logo" className="company-logo" />
          </div>
          {/* Second Column: GDPR Logo */}
          <div className="footer-column">
            <img src={gdpr} alt="GDPR Logo" className="gdpr-logo" />
          </div>
          {/* Third Column: Menu */}
          <div className="footer-column">
            <ul className="footer-menu">
              <li>
                <Link to="/" onClick={scrollToTop}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/" onClick={scrollToTop}>
                  Services
                </Link>
              </li>
              <li>
                <Link to="/" onClick={scrollToTop}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          {/* Fourth Column: Social Media Icons */}
          <div className="footer-column">
            <div className="social-icons">
              <a href="https://www.facebook.com">
                <FaFacebook />
              </a>
              <a href="https://www.twitter.com">
                <FaTwitter />
              </a>
              <a href="https://www.instagram.com">
                <FaInstagram />
              </a>
              <a href="https://www.linkedin.com">
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div>
        {/* Bottom Row: Copyright and Legal Links */}
        <div className="footer-bottom">
          <div className="legal-links">
            <Link
              to="/terms"
              onClick={scrollToTop}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </Link>
            <Link
              to="/privacy"
              onClick={scrollToTop}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
          </div>
          <p>&copy; 2024 Nova Network. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
