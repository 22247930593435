// Services.jsx
import React from "react";
import "./Services.css"; // Import custom CSS for Services component styling
import market from "./marketing_intelligence_image.jpg";
import contact from "./contact_discovery_image.jpg";
import email from "./email_campaign_image.jpg";
import data from "./data_enrichment_image.jpg";
import CustomModal from "./CustomModal";
import {
  dataEnrichmentContent,
  emailCompaignContent,
  contactDiscoveryContent,
  marketIntelligenceContent,
} from "./service"; // Import content variables

const Services = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState("");
  const [modalTitle, setModalTitle] = React.useState("");

  React.useEffect(() => {
    const handleScroll = () => {
      const top = window.pageYOffset + window.innerHeight;
      const serviceRows = document.querySelectorAll(".service-row");

      serviceRows.forEach((row) => {
        const serviceRowTop = row.offsetTop;

        if (top > serviceRowTop) {
          row.classList.add("visible");
        }
      });
    };

    handleScroll(); // Check visibility on mount
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleModalOpen = (content, title) => {
    setModalContent(content);
    setModalTitle(title);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div id="service" className="services-container">
      <div className="services-header-container">
        <div className="services-header-content">
          <h1 className="services-header">Discover Our Services</h1>
          <p className="services-subheader">
            Unlock your potential with our comprehensive services
          </p>
        </div>
      </div>
      <div className="service-row">
        <div className="service-content">
          <h2>Marketing Intelligence</h2>
          <p>
            Nova Network provides innovative solutions to help businesses
            navigate market complexities. Our comprehensive approach involves
            gathering and analyzing data-driven insights to empower strategic
            decision-making and market penetration. Our marketing intelligence
            services offer valuable insights into three key areas:
          </p>

          <ul>
            <li>
              <strong>Competitive Landscape:</strong> Gain insights into
              competitors, partners, and technologies to inform strategies.
            </li>
            <li>
              <strong>Technology Landscape:</strong> Explore technology
              ecosystems to understand hardware, software, and infrastructure
              trends.
            </li>
            <li>
              <strong>Audience Profiling:</strong> Obtain insights into event
              attendees for targeted outreach and sponsorship opportunities.
            </li>
          </ul>

          <p>
            With Nova Network, businesses gain the edge needed to thrive in
            competitive markets.
          </p>
          <button
            className="read-more-button"
            onClick={() =>
              handleModalOpen(
                marketIntelligenceContent,
                "Marketing Intelligence"
              )
            }
          >
            Read More
          </button>
        </div>
        <div className="service-image">
          <img src={market} alt="Marketing Intelligence" />
        </div>
      </div>
      <div className="service-row alternate">
        <div className="service-content">
          <h2>Contact Discovery</h2>
          <p>
            In today's fast-paced business environment, reaching customers
            swiftly is crucial. However, acquiring relevant contacts for
            business expansion is arduous, with Sales & Marketing teams
            dedicating 40% of their time to this task. Nova Network's Contact
            Discovery service addresses these challenges by aggregating contact
            information, ensuring accuracy, and facilitating on-demand contact
            discovery. Our service overcomes common roadblocks like stale data
            and incomplete databases, offering customized solutions, verified
            contacts, and increased ROI on marketing and sales campaigns.
          </p>
          <button
            className="read-more-button"
            onClick={() =>
              handleModalOpen(contactDiscoveryContent, "Contact Discovery")
            }
          >
            Read More
          </button>
        </div>
        <div className="service-image">
          <img src={contact} alt="Contact Discovery" />
        </div>
      </div>
      <div className="service-row">
        <div className="service-content">
          <h2>Email Campaign</h2>
          <p>
            Email Campaigns are an essential tool for direct marketing to target
            audiences, fostering brand awareness, loyalty, and sales generation.
            In today's smartphone-dominated era, accessing emails has become the
            norm, making it an effective means of communication. Our Email
            Campaigns service offers tailored solutions for surveys, marketing
            initiatives, and audience segmentation. Additionally, it provides
            comprehensive tracking capabilities to monitor engagement and follow
            up with interested recipients.
          </p>
          <button
            className="read-more-button"
            onClick={() =>
              handleModalOpen(emailCompaignContent, "Email Campaign")
            }
          >
            Read More
          </button>
        </div>
        <div className="service-image">
          <img src={email} alt="Email Campaign" />
        </div>
      </div>
      <div className="service-row alternate">
        <div className="service-content">
          <h2>Data Enrichment</h2>
          <p>
            Nova Network specializes in Data Enrichment, enhancing client
            datasets with precise insights and strategic value. Through
            meticulous analysis and refinement, we ensure the accuracy and
            reliability of information, empowering businesses to make informed
            decisions. Our tailored solutions enable clients to navigate complex
            markets with confidence, seizing opportunities for growth and
            success.
          </p>
          <button
            className="read-more-button"
            onClick={() =>
              handleModalOpen(dataEnrichmentContent, "Data Enrichment")
            }
          >
            Read More
          </button>
        </div>
        <div className="service-image">
          <img src={data} alt="Data Enrichment" />
        </div>
      </div>
      <CustomModal
        showModal={showModal}
        handleClose={handleModalClose}
        content={modalContent}
        size="xl"
        title={modalTitle}
      />
    </div>
  );
};

export default Services;
