// FreeTrialModal.jsx
import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify"; // Import toast components
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import "./FreeTrialModal.css";

const FreeTrialModal = ({ show, onHide }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("sendEmail.php", formData);
      if (response.data.success) {
        toast.success(response.data.message); // Show success toast
        onHide(); // Close modal
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message); // Show error toast
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">
          Get Started with a Free Trial
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <div className="text-center">
            <Button variant="dark" type="submit" className="get-started-button">
              Get Started
            </Button>
          </div>
        </Form>
      </Modal.Body>
      <ToastContainer /> {/* Toast container component */}
    </Modal>
  );
};

export default FreeTrialModal;
