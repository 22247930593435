import React, { useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggle,
  NavbarCollapse,
} from "react-bootstrap";
import { Link as ScrollLink, Events, scrollSpy } from "react-scroll";
import { FaBars } from "react-icons/fa"; // Import hamburger icon
import "./Header.css"; // Import custom styles for the header
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import logo from "../../assets/nova-network-logo.png";

const Header = () => {
  const [activeMenuItem, setActiveMenuItem] = useState("home");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    Events.scrollEvent.register("begin", (to, element) => {
      setActiveMenuItem(to);
    });
    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
    };
  }, []);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Navbar bg="dark" variant="dark" expand="md" sticky="top">
      <div className="container">
        <NavbarBrand as={Link} to="/" onClick={closeMobileMenu}>
          <img
            src={logo} // Logo image source
            alt="Nova Network" // Alternative text for accessibility
            className="navbar-logo" // CSS class for logo styling if needed
          />
        </NavbarBrand>
        <NavbarToggle
          aria-controls="basic-navbar-nav"
          onClick={handleMobileMenuToggle}
        >
          <FaBars /> {/* Use hamburger icon component */}
        </NavbarToggle>
        <NavbarCollapse
          id="basic-navbar-nav"
          className={`justify-content-end ${isMobileMenuOpen ? "show" : ""}`}
        >
          <Nav className="ml-auto" onSelect={handleMobileMenuToggle}>
            <ScrollLink
              to="home"
              spy={true}
              smooth={true}
              duration={500}
              activeClass="active"
              className={`nav-link ${
                activeMenuItem === "home" ? "active" : ""
              }`}
              onClick={handleMobileMenuToggle}
            >
              Home
            </ScrollLink>
            <ScrollLink
              to="service"
              spy={true}
              smooth={true}
              duration={500}
              activeClass="active"
              className={`nav-link ${
                activeMenuItem === "service" ? "active" : ""
              }`}
              onClick={handleMobileMenuToggle}
            >
              Service
            </ScrollLink>
            <ScrollLink
              to="contact"
              spy={true}
              smooth={true}
              duration={500}
              activeClass="active"
              className={`nav-link ${
                activeMenuItem === "contact" ? "active" : ""
              }`}
              onClick={handleMobileMenuToggle}
            >
              Contact Us
            </ScrollLink>
          </Nav>
        </NavbarCollapse>
      </div>
    </Navbar>
  );
};

export default Header;
