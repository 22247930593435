// Home.jsx
import React from "react";
import * as THREE from "three";
import worldMapImage from "./worldmap.jpg"; // Import worldmap image
import "./Home.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";

import FreeTrialModal from "./FreeTrialModal";

const Home = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [animateButton, setAnimateButton] = React.useState(false); // State to trigger button animation

  React.useEffect(() => {
    setAnimateButton(true); // Trigger button animation on component mount
  }, []);

  React.useEffect(() => {
    // Setup scene
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    const canvasWidth = window.innerWidth / 4; // Smaller canvas width
    const canvasHeight = window.innerHeight / 4; // Smaller canvas height

    let canvasContainer = document.getElementById("world");
    if (!canvasContainer.querySelector("canvas")) {
      renderer.setSize(canvasWidth, canvasHeight);
      renderer.setClearColor(0xffffff); // Set canvas background color to white
      canvasContainer.appendChild(renderer.domElement);

      // Check if the scene already contains the sphere
      let sphere = scene.getObjectByName("worldSphere");

      if (!sphere) {
        // Sphere with world map texture
        const geometry = new THREE.SphereGeometry(6, 32, 32); // Larger size
        const textureLoader = new THREE.TextureLoader();
        const texture = textureLoader.load(worldMapImage); // Use worldmap image
        const material = new THREE.MeshBasicMaterial({ map: texture });
        sphere = new THREE.Mesh(geometry, material);
        sphere.name = "worldSphere"; // Set name to identify the sphere
        scene.add(sphere);
      }

      // Camera position
      camera.position.z = 10;

      // Animation
      const animate = () => {
        requestAnimationFrame(animate);
        sphere.rotation.y += 0.005; // Slower rotation
        renderer.render(scene, camera);
      };

      animate();
    }

    return () => {
      // Cleanup
      renderer.dispose();
    };
  }, []);

  return (
    <>
      <div id="home" className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-4">
            <div id="world"></div>
          </div>
          <div className="col-lg-8">
            <div className="tagline-container">
              <h1 className="tagline">
                <span
                  className="tagline-line tagline-animation"
                  data-text="Get & Retain Clients"
                ></span>
              </h1>
              <h1 className="tagline" style={{ color: "#ff8080" }}>
                <span
                  className="tagline-line tagline-animation"
                  data-text="with Data Precision"
                ></span>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="free-trial-container">
        <button
          className={`free-trial-button ${animateButton ? "animate" : ""}`}
          onClick={() => setModalShow(true)}
        >
          <span className="text">Free Trial</span>
          <FontAwesomeIcon icon={faGreaterThan} className="icon" />
        </button>
        <FreeTrialModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    </>
  );
};

export default Home;
