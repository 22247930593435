// service.js
const marketIntelligenceContent = `
<p>
    According to Peter Drucker, “Great wisdom not applied to action
    and behavior is meaningless data.” All organizations irrespective
    of big or small face similar challenges in understanding the
    market. Nova Network brings to you an exciting way to know about
    your customers, competitors and also customers of your
    competitors.
</p>
<p>
    Here we gather relevant information of company's markets, this
    information is gathered and analyzed specifically for the purpose
    of accurate decision making in determining market opportunity and
    market penetration strategies. We can do marketing intelligence
    for you in three different aspects.
</p>
<ol>
    <li>
    <strong>Competitive Landscape:</strong>
    <p>
        In Competitive Landscape we provide you with insight of your
        competitive install-base. This includes information about your
        competitors, competitive partners, category of technology used
        etc. Additionally, it will give an insight of your competitive
        customers and similar companies like them.
    </p>
    </li>
    <li>
    <strong>Technology Landscape:</strong>
    <p>
        Here, we provide Technology Landscape of business enterprise,
        which can include finding technology landscape of named
        accounts, types of companies within specific market segments,
        geographies, etc. Leveraging search technologies, processes
        and skilled domain experts; Nova Network can provide you with
        accurate insights into technology ecosystem, including aspects
        like hardware, applications, infrastructure, networking,
        security, data management, etc. This exploration covers
        various elements like Enterprise Packages (ERP, ECM, CRM, SCM,
        etc.), Point Solutions (HR, Recruitment & Talent Management,
        Accounting, etc.), Specific Usage (Voice, Data Management,
        etc.), and Industry/Domain Specific Technology (Health care -
        HIS/EHR, Financial Services - Portfolio Management, etc.);
        among others.
    </p>
    </li>
    <li>
    <strong>Audience Profiling:</strong>
    <p>
        In Audience Profiling, we provide insight on attendees
        participating in competitor's events or other similar events
        in same industry. This will help you in targeting attendees of
        your competing events or closely related trade shows/events
        for sponsorships and participation. For any event to be a
        successful one, it's very necessary to have a landscape about
        your competitors' events or any other events in the industry.
        This way you can profile the right audience to reach out to
        market your own event.
    </p>
    </li>
</ol>
`;

const emailCompaignContent = `
    <p>Email Campaigns are lately used as direct marketing your commercial message to your desired target audience. Our Email Campaigns service will work for brand awareness, building loyalty, solicit sales and lastly maintain cordial relationships with clients, customers or partners.</p>
    <p>In today's fast moving space who has time to respond to mail or print messages. As you all now we are in SMARTPHONE generation, so our messages should also be delivered smartly.</p>
    <p>Research has proven that more than 90% of people access their emails through their smartphones. Email is the best option for reaching out to your audience.</p>
    <p>What will we do?</p>
    <ul>
        <li>Our services are customized according to your requirements; clients can use our Email Campaigns service for Surveys, Marketing, New Innovations, and targeting to specific and segmented audience.</li>
        <li>Through this service you will also be able to have a track of those who have viewed, read or opted out your email. On the reports generated through tracking of email you can follow-up with audience interested with your services.</li>
    </ul>
`;

const contactDiscoveryContent = `
    <p>
        In today's fast-paced world, being agile is essential to reach
        customers quickly. However, finding relevant contacts for business
        expansion is a tedious task, with Sales & Marketing teams spending
        around 40% of their time on this endeavor.
    </p>
    <p>
        Hence, sourcing the right target contact is both tedious and
        painstaking. There is a need for aggregating contact information
        of relevant roles/titles, achieving high accuracy and definite
        validation across target markets, and enabling on-demand contact
        discovery. Filling the gaps in contact details such as Name,
        Title, Phone, and Email is crucial, as well as having a global
        spread of business contacts.
    </p>
    <p>
        Building an effective Contact Database faces common roadblocks
        such as stale, unorganized, incomplete, and redundant data, which
        hinders proper execution of sales & marketing campaigns. Precious
        marketing hours are wasted on cleaning data, leading to incomplete
        and stale databases, resulting in high rates of email bounce and
        call drops.
    </p>
    <p>
        Off-the-shelf databases often offer only selective key executives,
        lacking role-based list building and global coverage. These
        challenges make it difficult to justify spending on procuring
        business data.
    </p>
    <p>
        Nova Network's Contact Discovery service addresses these
        challenges by providing a customized contact database that meets
        clients' requirements. We offer relevant and verified contact
        databases, leading to increased ROI on Marketing Campaigns and
        Inside Sales Campaigns.
    </p>
`;

const dataEnrichmentContent = `
    <ul>
    <li>
        <strong>Precision Enhancement:</strong> Nova Network's Data Enrichment
        service is dedicated to refining and enhancing client datasets with
        precise insights. Through advanced algorithms and thorough analysis,
        we ensure that the data provided is accurate and reliable, empowering
        businesses to make informed decisions confidently.
    </li>
    <li>
        <strong>Strategic Value Addition:</strong> Beyond mere data accuracy,
        our service adds strategic value to the information. We focus on
        enriching the data with additional context, such as market trends,
        consumer behavior patterns, and competitive landscape analysis. This
        enriched data helps businesses gain deeper insights into their target
        audience and market dynamics.
    </li>
    <li>
        <strong>Tailored Solutions:</strong> We understand that every business
        has unique needs and challenges. Therefore, our Data Enrichment
        service offers tailored solutions that are customized to meet the
        specific requirements of each client. Whether it's refining existing
        datasets or integrating external sources of information, we ensure
        that the solution aligns with the client's objectives and goals.
    </li>
    <li>
        <strong>Decision-Making Support:</strong> With enriched data at their
        disposal, businesses can make more informed decisions across various
        aspects of their operations. Whether it's optimizing marketing
        strategies, identifying new market opportunities, or enhancing
        customer experiences, our Data Enrichment service provides the
        necessary insights to drive business growth and success.
    </li>
    <li>
        <strong>Competitive Advantage:</strong> By leveraging enriched data,
        businesses can gain a competitive advantage in their respective
        industries. They can stay ahead of market trends, anticipate consumer
        needs, and respond swiftly to changes in the business landscape. This
        proactive approach allows businesses to outperform competitors and
        stay relevant in today's dynamic market environment.
    </li>
    <li>
        <strong>Continuous Improvement:</strong> Data enrichment is not a
        one-time process but an ongoing endeavor. Nova Network ensures
        continuous improvement by regularly updating and refining the enriched
        datasets. We incorporate feedback from clients, monitor market
        dynamics, and adapt our strategies to ensure that the data remains
        relevant and valuable over time.
    </li>
    <li>
        <strong>Compliance and Security:</strong> We prioritize data privacy,
        security, and compliance with regulatory standards. Our Data
        Enrichment service adheres to industry best practices and compliance
        requirements, ensuring that client data is handled responsibly and
        ethically. We employ robust security measures to safeguard sensitive
        information and mitigate risks associated with data breaches or
        unauthorized access.
    </li>
    </ul>
    <p>
        In summary, Nova Network's Data Enrichment service offers a
        comprehensive solution for businesses seeking to enhance the quality,
        accuracy, and strategic value of their datasets. Through precision
        enhancement, tailored solutions, decision-making support, and continuous
        improvement, we empower businesses to unlock new opportunities, drive
        growth, and maintain a competitive edge in today's data-driven world.
    </p>
`;

export {
  dataEnrichmentContent,
  emailCompaignContent,
  contactDiscoveryContent,
  marketIntelligenceContent,
};
